import React from "react";
import Img from "gatsby-image";

const Feature = ({ feature }) => (
    <div className="mb-6">
        <div
            className="item"
            data-sal="slide-up"
            data-sal-easing="ease-in-cubic"
        >
            <div >
                <Img fluid={feature.image.childImageSharp.fluid} className="w-16 mr-2" />
            </div>
            <div className="item__content">
                <h3 className="item__title">{feature.title}</h3>
                <p
                    className="item__text md:w-3/4"
                    dangerouslySetInnerHTML={{ __html: feature.body }}
                />
            </div>
        </div>
    </div>
);

export default Feature;
