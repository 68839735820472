import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SectionSeparator from "components/sectionSeparator";

export const query = graphql`
    fragment mission on Language {
        mission {
            heading
            description
            background
            body
            image {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    }
`;

const Mission = ({ data }) => {
    return (
        <section
            id="mission"
            className={`pricing section bg-${data.background}`}
        >
            <div className="container mx-auto">
                <div className="section__two_columns">
                    <div>
                        <SectionSeparator />
                        <h2
                            className="section__title"
                            data-sal="fade"
                            data-sal-easing="ease-in-cubic"
                            dangerouslySetInnerHTML={{ __html: data.heading }}
                        ></h2>
                        <h3
                            className="section__subtitle"
                            data-sal-easing="ease-in-cubic"
                            data-sal-delay="100"
                        >
                            {data.description}
                        </h3>
                        <div
                            dangerouslySetInnerHTML={{ __html: data.body }}
                        ></div>
                    </div>
                    <div>
                        <Img
                            fluid={data.image.childImageSharp.fluid}
                            className="mx-auto"
                            alt="Mission"
                            data-sal="slide-top"
                            data-sal-delay="400"
                            data-sal-duration="500"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Mission;
