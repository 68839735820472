import React from "react";

const DemoItem = ({ benefit, inverted }) => (
    <div
        className={"demo__item " + (inverted ? "demo__item__inverted" : "")}
        data-sal="slide-up"
        data-sal-easing="ease-in-cubic"
    >
        <div className="item">
            <div className="item__content">
                <h3 className="item__title">{benefit.title}</h3>
                <p
                    className="item__text md:w-3/4"
                    dangerouslySetInnerHTML={{ __html: benefit.body }}
                />
            </div>
        </div>
    </div>
);

export default DemoItem;
