import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SectionSeparator from "components/sectionSeparator";

export const query = graphql`
    fragment hero on Language {
        hero {
            heading
            subheading
            background
            description
            ctaText
            ctaUrl
            image {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    }
`;

const Hero = ({ data }) => {
    return (
        <section
            className={`hero container section mx-auto bg${data.background}`}
        >
            <div className="hero__tagline">
                <div className="hero__tagline-content-wrap">
                    <SectionSeparator />
                    <p
                        className="hero__tagline-subtitle"
                        data-sal="fade"
                        data-sal-delay="100"
                    >
                        {data.subheading}
                    </p>
                    <h2
                        className="hero__tagline-title"
                        data-sal="fade"
                        dangerouslySetInnerHTML={{ __html: data.heading }}
                    />
                    <p
                        className="hero__tagline-text"
                        data-sal="fade"
                        data-sal-delay="200"
                    >
                        {data.description}
                    </p>
                    <a href={data.ctaUrl}>
                        <button
                            className="btn btn--primary mt-8"
                            data-sal="fade"
                            data-sal-delay="300"
                        >
                            {data.ctaText}
                        </button>
                    </a>
                </div>
            </div>
            <div className="hero__image">
                <Img
                    fluid={data.image.childImageSharp.fluid}
                    className="mx-auto"
                    alt="Hero"
                    data-sal="slide-right"
                    data-sal-delay="400"
                    data-sal-duration="500"
                />
            </div>
        </section>
    );
};

export default Hero;
