import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import DemoItem from "components/demoItem";
import SectionSeparator from "components/sectionSeparator";
import VideoModal from "components/videoModal";

export const query = graphql`
    fragment demo on Language {
        demo {
            id
            heading
            description
            background
            videoButton
            benefits {
                title
                body
            }
            video {
                publicURL
            }
            image {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    }
`;

const Demo = ({ data }) => {
    return (
        <section id="demo" className={`demo section bg-${data.background}`}>
            <div className="container mx-auto">
                <div>
                    <SectionSeparator />
                    <h2
                        className="section__title"
                        data-sal="fade"
                        data-sal-easing="ease-in-cubic"
                        dangerouslySetInnerHTML={{ __html: data.heading }}
                    ></h2>
                    <h3
                        className="section__subtitle"
                        data-sal-easing="ease-in-cubic"
                        data-sal-delay="100"
                    >
                        {data.description}
                    </h3>
                    <div className="demo__content">
                        <div className="demo__benefits">
                            {data.benefits
                                .slice(0, data.benefits.length / 2)
                                .map((benefit, index) => (
                                    <DemoItem key={index} benefit={benefit} />
                                ))}
                        </div>
                        <div className="demo__visual">
                            <div>
                                <div className="demo__image__wrapper">
                                    <Img
                                        fluid={data.image.childImageSharp.fluid}
                                        className="mx-auto"
                                        alt="Demo"
                                        data-sal="slide-top"
                                        data-sal-delay="400"
                                        data-sal-duration="500"
                                    />
                                </div>
                                {data.videoButton && (
                                    <div className="demo__image__button text-center">
                                        <button
                                            className="btn btn--primary"
                                            data-sal="fade"
                                            data-sal-delay="300"
                                            onClick={() => {
                                                document
                                                    .getElementById(
                                                        "video-modal"
                                                    )
                                                    .classList.remove("hidden");
                                                document
                                                    .getElementById("video")
                                                    .play();
                                            }}
                                        >
                                            {data.videoButton}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="demo__benefits">
                            {data.benefits
                                .slice(
                                    data.benefits.length / 2,
                                    data.benefits.length
                                )
                                .map((benefit, index) => (
                                    <DemoItem
                                        inverted
                                        key={index}
                                        benefit={benefit}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <VideoModal video={data.video} />
        </section>
    );
};

export default Demo;
