import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Glider from "glider-js";
import Testimonial from "components/testimonial";
import SectionSeparator from "components/sectionSeparator";

export const query = graphql`
    fragment testimonials on Language {
        testimonials {
            id
            heading
            description
            background
            testimonials {
                name
                company
                comment
                image {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_noBase64
                            ...GatsbyImageSharpFluidLimitPresentationSize
                        }
                    }
                }
            }
        }
    }
`;

const Testimonials = ({ data }) => {
    const initSlider = () => {
        new Glider(document.querySelector(".glider"), {
            slidesToShow: 1,
            dots: ".glider__dots",
            draggable: true,
            arrows: {
                prev: ".glider-prev",
                next: ".glider-next",
            },
        });
    };

    useEffect(() => {
        initSlider();
    });

    return (
        <section id="testimonials" className={`section bg-${data.background}`}>
            <div className="mx-auto container">
                <div>
                    <SectionSeparator />
                    <h2
                        className="section__title"
                        data-sal="fade"
                        data-sal-easing="ease-in-cubic"
                        dangerouslySetInnerHTML={{ __html: data.heading }}
                    ></h2>
                    <p
                        className="w-full"
                        data-sal="fade"
                        data-sal-easing="ease-in-cubic"
                    >
                        {data.description}
                    </p>
                    {data.testimonials.length > 0 && (
                        <div
                            className="testimonial__slider mt-8"
                            data-sal="fade"
                            data-sal-easing="ease-in-cubic"
                        >
                            <div className="glider">
                                {data.testimonials.map((testimonial, index) => (
                                    <Testimonial
                                        testimonial={testimonial}
                                        key={index}
                                    />
                                ))}
                            </div>
                            <button className="glider-prev material-icons">
                                keyboard_arrow_left
                            </button>
                            <button className="glider-next material-icons">
                                keyboard_arrow_right
                            </button>
                            <div className="glider__dots"></div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
