import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Section from "sections/section";

export const query = graphql`
    query($language: String!) {
        ...seo
        lang(key: $language) {
            ...footer
            ...about
            ...contact
            ...demo
            ...features
            ...hero
            ...mission
            ...pricing
            ...testimonials
            layout {
                title
                description
                menu {
                    menuItems {
                        title
                        url
                    }
                }
                contentModule {
                    type
                }
            }
        }
    }
`;

const LandingPage = ({
    data: {
        seo,
        lang: { layout, footer, ...data },
    },
    pageContext: { language, translations },
}) => {
    return (
        <Layout menu={layout.menu} footer={footer} translations={translations}>
            <SEO
                title={layout.title}
                description={layout.description}
                language={language}
                seo={seo}
            />
            {layout.contentModule.map((content, index) => (
                <Section
                    type={content.type}
                    key={index}
                    data={data[content.type.toLowerCase()]}
                />
            ))}
        </Layout>
    );
};

export default LandingPage;
