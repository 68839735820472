import React from "react";

const AboutItem = ({ advantage }) => (
    <li className="mb-6">
        <div
            className="item"
            data-sal="slide-up"
            data-sal-easing="ease-in-cubic"
        >
            <div className="item__content">
                <h3 className="item__title md:w-3/4">{advantage.title}</h3>
                <p
                    className="item__text md:w-3/4"
                    dangerouslySetInnerHTML={{ __html: advantage.body }}
                />
            </div>
        </div>
    </li>
);

export default AboutItem;
