import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import validation from "utils/validation";
import { createContact } from "utils/api";
import Alert from "components/alert";
import SectionSeparator from "components/sectionSeparator";

const CONSTRAINTS = {
    name: {
        presence: true,
    },
    email: {
        presence: false,
        email: true,
    },
    message: {
        presence: true,
    },
    phone: {
        presence: false,
    },
};

export const query = graphql`
    fragment contact on Language {
        contact {
            heading
            description
            links {
                text
                link
            }
            background
            form {
                name {
                    label
                    placeholder
                }
                email {
                    label
                    placeholder
                }
                phone {
                    label
                    placeholder
                }
                message {
                    label
                    placeholder
                }
                send {
                    text
                }
                alert {
                    title
                    message
                    close
                }
                consent {
                    description
                    label
                }
            }
        }
    }
`;

const Contact = ({ data }) => {
    const [consent, setConsent] = useState(false);

    useEffect(() => {
        validation.init("contact_form", CONSTRAINTS, () => {
            createContact({
                name: document.getElementById("name").value,
                email: document.getElementById("email").value,
                message: document.getElementById("message").value,
                phone: document.getElementById("phone").value,
            });
            document.getElementById("message").value = "";
            document.getElementById("alert").classList.remove("hidden");
        });
    }, []);

    return (
        <section
            id="contact"
            className={`contact section bg-${data.background}`}
        >
            <Alert {...data.form.alert} />
            <div className="container contact__container mx-auto">
                <div className="contact__content">
                    <SectionSeparator />
                    <h2
                        className="section__title"
                        data-sal="fade"
                        data-sal-easing="ease-in-cubic"
                        dangerouslySetInnerHTML={{ __html: data.heading }}
                    ></h2>
                    <p
                        className="mb-4 w-full md:w-3/4"
                        data-sal="slide-up"
                        data-sal-easing="ease-in-cubic"
                    >
                        {data.description}
                    </p>
                    <div>
                        {data.links.map((link, index) => (
                            <div key={index} className="text-2xl">
                                <strong>
                                    {link.link ? (
                                        <a href={link.link}>
                                            {link.text}
                                            <i className="material-icons text-xl">
                                                arrow_right_alt
                                            </i>
                                        </a>
                                    ) : (
                                        <span>
                                            {link.text}
                                            <i className="material-icons text-xl">
                                                arrow_right_alt
                                            </i>
                                        </span>
                                    )}
                                </strong>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <form
                        id="contact_form"
                        className="w-full md:w-3/4"
                        noValidate
                        data-sal="slide-up"
                        data-sal-easing="ease-in-cubic"
                        data-sal-delay="100"
                    >
                        <div className="input-group mb-2">
                            <label htmlFor="name">{data.form.name.label}</label>
                            <input
                                type="text"
                                id="name"
                                className="input"
                                name="name"
                                placeholder={data.form.name.placeholder}
                            />
                        </div>
                        <div className="input-group mb-2">
                            <label htmlFor="email">
                                {data.form.email.label}
                            </label>
                            <input
                                type="email"
                                id="email"
                                className="input"
                                name="email"
                                placeholder={data.form.email.placeholder}
                            />
                        </div>
                        <div className="input-group mb-2">
                            <label htmlFor="phone">
                                {data.form.phone.label}
                            </label>
                            <input
                                type="text"
                                id="phone"
                                className="input"
                                name="phone"
                                placeholder={data.form.phone.placeholder}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="message">
                                {data.form.message.label}
                            </label>
                            <textarea
                                id="message"
                                className="h-20"
                                name="message"
                                placeholder={data.form.message.placeholder}
                            ></textarea>
                        </div>
                        <div>
                            <p
                                className="mb-2 mt-2 text-sm raw"
                                dangerouslySetInnerHTML={{
                                    __html: data.form.consent.description,
                                }}
                            />
                            <input
                                type="checkbox"
                                onClick={event =>
                                    setConsent(event.target.checked)
                                }
                            />{" "}
                            <label
                                className="raw"
                                dangerouslySetInnerHTML={{
                                    __html: data.form.consent.label,
                                }}
                            />
                        </div>
                        <button
                            type="submit"
                            className="btn btn--primary btn--disabled mt-8"
                            disabled={!consent}
                        >
                            {data.form.send.text}
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Contact;
