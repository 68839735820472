import React from "react";

export default function VideoModal({ video }) {
    return (
        <div
            id="video-modal"
            className="fixed inset-0 overflow-y-auto hidden"
            aria-labelledby="modal-title"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
            aria-hidden="true"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-center justify-center min-h-screen p-4 text-center">
                <div
                    id="video-modal-overlay"
                    className="fixed inset-0 bg-gray bg-opacity-75 p-4"
                    onClick={() => {
                        document
                            .getElementById("video-modal")
                            .classList.add("hidden");
                        document.getElementById("video").pause();
                        document.getElementById("video").currentTime = 0;
                    }}
                >
                    <div className="text-right cursor-pointer select-none">
                        <i className="material-icons text-xl ">close</i>
                    </div>
                </div>

                <span className="hidden" aria-hidden="true">
                    &#8203;
                </span>

                <div className="inline-block bg-white text-left overflow-hidden shadow-xl transform transition-all align-middle">
                    <video id="video" src={video.publicURL} controls />
                </div>
            </div>
        </div>
    );
}
