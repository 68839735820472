import React from "react";
import { graphql } from "gatsby";

import AboutItem from "components/aboutItem";
import SectionSeparator from "components/sectionSeparator";

export const query = graphql`
    fragment about on Language {
        about {
            heading
            description
            background
            advantageItem {
                title
                body
            }
        }
    }
`;

const About = ({ data }) => {
    return (
        <section id="about" className={`about-us bg-${data.background}`}>
            <div className="container section mx-auto">
                <div className="about-us__content">
                    <SectionSeparator />
                    <h2
                        className="section__title"
                        data-sal="fade"
                        data-sal-easing="ease-in-cubic"
                        dangerouslySetInnerHTML={{ __html: data.heading }}
                    ></h2>
                    <p
                        data-sal="slide-up"
                        data-sal-easing="ease-in-cubic"
                        data-sal-delay="100"
                    >
                        {data.description}
                    </p>
                    {data.advantageItem.length > 0 && (
                        <ul className="about-us__items">
                            {data.advantageItem.map((advantage, index) => (
                                <AboutItem advantage={advantage} key={index} />
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </section>
    );
};

export default About;
