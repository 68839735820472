import React from "react";
import PropTypes from "prop-types";
import Hero from "sections/hero";
import About from "sections/about";
import Testimonials from "sections/testimonials";
import Features from "sections/features";
import Pricing from "sections/pricing";
import Mission from "sections/mission";
import Contact from "sections/contact";
import Demo from "sections/demo";

// Dynamically import or require sections inside the section folder
const components = {
    Hero,
    About,
    Testimonials,
    Features,
    Pricing,
    Mission,
    Contact,
    Demo,
};

const Section = ({ type, data }) => {
    if (typeof components[type] === "undefined") {
        return "";
    }

    return React.createElement(components[type], { data });
};

Section.prototype = {
    contentModuleId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default Section;
