import React from "react";
import { graphql } from "gatsby";

import Feature from "components/feature";
import SectionSeparator from "components/sectionSeparator";

export const query = graphql`
    fragment features on Language {
        features {
            heading
            description
            background
            featureItems {
                title
                body
                image {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_noBase64
                            ...GatsbyImageSharpFluidLimitPresentationSize
                        }
                    }
                }
            }
            buttonItems {
                text
                link
                primary
            }
        }
    }
`;

const Features = ({ data }) => {
    return (
        <section
            id="features"
            className={`features section bg-${data.background}`}
        >
            <div className="container mx-auto">
                <div className="features__content">
                    <SectionSeparator />
                    <h2
                        className="section__title"
                        data-sal="fade"
                        data-sal-easing="ease-in-cubic"
                        dangerouslySetInnerHTML={{ __html: data.heading }}
                    ></h2>
                    <p
                        data-sal="slide-up"
                        data-sal-easing="ease-in-cubic"
                        data-sal-delay="100"
                    >
                        {data.description}
                    </p>
                    <div className="features__items">
                        {(data.featureItems || []).map((feature, index) => (
                            <Feature feature={feature} key={index} />
                        ))}
                        <div>
                            {(data.buttonItems || []).map((button, index) => (
                                <div className="mt-8 mb-8" key={index}>
                                    <a
                                        className={`btn ${
                                            button.primary ? "btn--primary" : ""
                                        }`}
                                        data-sal="fade"
                                        data-sal-delay="300"
                                        href={button.link}
                                    >
                                        {button.text}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Features;
